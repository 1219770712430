<script setup lang="ts">
	import Navbar from "~/layouts/Navbar.vue";
	import Bottombar from "~/layouts/Bottombar.vue";
	// import Footer from "~/layouts/Footer.vue";
	const head = useLocaleHead({
		addDirAttribute: true,
		identifierAttribute: "id",
		addSeoAttributes: true,
	});
</script>
<template>
	<div>
		<Html :lang="head.htmlAttrs.lang">
			<Body>
				<div>
					<Navbar />
					<slot />
				</div>
				<Bottombar />
			</Body>
		</Html>
	</div>
</template>
